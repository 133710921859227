import { PromotionType } from '../pages/Promotions/types';
import { Promotion } from '../types/promotion';
import { api } from './config';

export const listPromotions = async ({
  id,
  page,
  limit,
  query,
  status,
  type,
  order,
  companyId,
}: {
  page?: number;
  limit?: number;
  query?: string;
  type?: string;
  status?: string;
  order?: string;
  id: string;
  companyId?: string;
}) => {
  const searchFilter = query ? `&query=${query}` : '';
  const statusFilter = status ? `&status[]=${status}` : '';
  const typeFilter = type ? `&type[]=${type}` : '';
  const orderFilter = order ? `&order=${order}` : '&order=created_at,asc';

  const { data } = await api.get(
    `/v2/partners/promotions?limit=${limit ?? 10}&page=${
      page ?? 1
    }${searchFilter}${statusFilter}${typeFilter}${orderFilter}`,
    {
      headers: {
        company_id: companyId ?? id,
      },
    }
  );
  return data;
};

export const createPromotion = async <T>(
  promotion: PromotionType,
  companyId: string,
  companyNetworkSelectedId?: string
) => {
  const { status, data } = await api.post(
    '/v2/partners/promotions',
    {
      ...promotion,
      company_id: companyId,
      start_datetime: promotion.start_date,
      end_datetime: promotion.end_date,
    },
    {
      headers: {
        company_id: companyNetworkSelectedId ?? companyId,
      },
    }
  );

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};
export const uploadBanner = async <T>(id: string, photo: File) => {
  const form = new FormData();
  form.append('banner', photo as File);

  const { status, data } = await api.put(`/v2/partners/promotions/${id}`, form);

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const updateBanner = async <T>(id: string, photo: File) => {
  const form = new FormData();
  form.append('banner', photo as File);

  const { status, data } = await api.patch(
    `/v2/partners/promotions/${id}/banner`,
    form
  );
  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const pausePromotion = async ({
  promotion,
}: {
  promotion: PromotionType;
}) => {
  return await api.patch(`/v2/partners/promotions/${promotion.id}/pause`);
};

export const restaurePromotion = async ({
  promotion,
}: {
  promotion: PromotionType;
}) => {
  return await api.patch(`/v2/partners/promotions/${promotion.id}/restaure`);
};

export const deletePromotion = async ({
  promotion,
}: {
  promotion: PromotionType;
}) => {
  return await api.delete(`/v2/partners/promotions/${promotion.id}/delete`);
};

export const updatePromotion = async <T>(
  id: string,
  promotion: Partial<Promotion>
) => {
  const { status, data } = await api.put(
    `/v2/partners/promotions/${id}`,
    promotion
  );

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};
