import { StatusBar, Style } from '@capacitor/status-bar';
import { IonReactRouter } from '@ionic/react-router';
import { lazy, useCallback, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router';
import Route from './Route';

import useAuth from '../hooks/useAuth';

import { Capacitor } from '@capacitor/core';
import WalletProvider from '../contexts/WalletContext';
import { OrdersProvider } from '../pages/Orders/context';

import OrderContextProvider from '../contexts/OrdersContext';
import CashbacksExpired from '../pages/CashbacksExpired';
import CreatePromotion from '../pages/CreatePromotion';
import { CartProvider } from '../pages/ExtraServices/context';
import Promotions from '../pages/Promotions';
import AddPaymentForm from '../pages/Cards/AddPaymentForm';
import ExtraServicesCheckoutSuccess from '../pages/ExtraServicesCheckoutSuccess';
import { Reports } from '../pages/Reports';
import PergunteAlbertChat from '../pages/PergunteAlbert/chat';
import PergunteAlbert from '../pages/PergunteAlbert';
import { MessagesProvider } from '../pages/PergunteAlbert/MessagesContext';
import { ChatProvider } from '../pages/PergunteAlbertAI/ChatContext';
import PergunteAlbertAI from '../pages/PergunteAlbertAI';
import PergunteAlbertAIChat from '../pages/PergunteAlbertAI/chat';

const Home = lazy(() => import('../pages/Home'));
const HomePDV = lazy(() => import('../pages/HomePDV'));
const HomeDelivery = lazy(() => import('../pages/HomeDelivery'));
const SelectCompany = lazy(() => import('../pages/SelectCompany'));
const CreateCompany = lazy(() => import('../pages/CreateCompany'));
const Notifications = lazy(() => import('../pages/Notifications'));
const CheckIn = lazy(() => import('../pages/CheckIn'));
const CheckInResume = lazy(() => import('../pages/CheckInResume'));
const Settings = lazy(() => import('../pages/Settings'));
const ExtraServices = lazy(() => import('../pages/ExtraServices'));
const ExtraServicesCheckout = lazy(
  () => import('../pages/ExtraServicesCheckout')
);
const Gallery = lazy(() => import('../pages/Gallery'));
const Users = lazy(() => import('../pages/Users'));
const User = lazy(() => import('../pages/User'));
const Cards = lazy(() => import('../pages/Cards'));
const Token = lazy(() => import('../pages/TokenApi'));
const Invoices = lazy(() => import('../pages/Invoices'));
const Cashback = lazy(() => import('../pages/Cashback'));
const CompanyProfile = lazy(() => import('../pages/CompanyProfile'));
const Financial = lazy(() => import('../pages/Financial'));
const FinancialOrders = lazy(() => import('../pages/FinancialOrders'));
const TicketList = lazy(
  () => import('../pages/Financial/components/TicketList')
);
const Balance = lazy(() => import('../pages/Balance'));
const Withdraw = lazy(() => import('../pages/Withdraw'));
const Receipt = lazy(() => import('../pages/Receipt'));
const Card = lazy(() => import('../pages/Card'));
const Profile = lazy(() => import('../pages/Profile'));
const Items = lazy(() => import('../pages/Items'));
const CreateItem = lazy(() => import('../pages/CreateItem'));
const EditItem = lazy(() => import('../pages/EditItem'));
const Menus = lazy(() => import('../pages/Menus'));
const Login = lazy(() => import('../pages/Login/index'));
const LoginMaster = lazy(() => import('../pages/LoginMaster/index'));
const Authenticator = lazy(() => import('../pages/Login/Authenticator'));
const TokenValidator = lazy(() => import('../pages/Login/TokenValidator'));
const RecoveryPassword = lazy(() => import('../pages/RecoveryPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const Offers = lazy(() => import('../pages/Offers'));
const CreateOffer = lazy(() => import('../pages/CreateOffer'));
const EditOffer = lazy(() => import('../pages/EditOffer'));
const Terms = lazy(() => import('../pages/Terms'));
const MyTerm = lazy(() => import('../pages/MyTerm'));
const Onboarding = lazy(() => import('../pages/Onboarding'));
const OnboardingPayment = lazy(() => import('../pages/OnboardingPayment'));
const OpeningHours = lazy(() => import('../pages/OpeningHours'));
const EditOpeningHours = lazy(() => import('../pages/OpeningHours/Edit'));
const BulkEditOpeningHours = lazy(
  () => import('../pages/OpeningHours/BulkEdit')
);

const OnboardingPaymentConfirm = lazy(
  () => import('../pages/OnboardingPayment/Confirm')
);
const Guides = lazy(() => import('../pages/Guides/Guides'));
const PlanPayment = lazy(() => import('../pages/PlanPayment'));
const PlanPaymentSuccess = lazy(() => import('../pages/PlanPaymentSuccess'));

const HomeTypes: any = {
  default: Home,
  pdv: HomePDV,
  delivery: HomeDelivery,
};

const Routes = () => {
  const { user, company, loading } = useAuth();
  const location = useLocation();
  const configStatusBar = useCallback(async (mode: string = 'dark') => {
    const isWeb = Capacitor.getPlatform() === 'web';
    const isMobile = Capacitor.getPlatform() === 'mobile';
    if (isMobile && !isWeb) {
      await StatusBar.setStyle({
        style: mode === 'dark' ? Style.Dark : Style.Light,
      });
    }
  }, []);

  useEffect(() => {
    if (
      [
        '/login',
        '/recovery-password',
        '/reset-password',
        '/',
        '/pergunte-ao-abert',
      ].includes(location.pathname) &&
      !user
    ) {
      document.body.classList.remove('bg-white');
      document.body.classList.add('bg-gray-900');
      document.documentElement.style.setProperty(
        '--ion-background-color',
        '#121111'
      );
      configStatusBar('dark');
    } else {
      document.body.classList.remove('bg-gray-900');
      document.body.classList.add('bg-white');
      document.documentElement.style.setProperty(
        '--ion-background-color',
        '#ffffff'
      );
      configStatusBar('light');
    }
  }, [configStatusBar, location, user]);

  return (
    <IonReactRouter>
      <Route
        exact
        path={'/login'}
        component={(props: any) =>
          user ? (
            location && <Redirect to={location.pathname} />
          ) : (
            <Login {...props} />
          )
        }
      />
      <Route
        exact
        path={'/admin/login'}
        component={(props: any) =>
          user ? (
            location && <Redirect to={location.pathname} />
          ) : (
            <LoginMaster {...props} />
          )
        }
      />
      <Route
        exact
        path={'/authenticator/:document'}
        component={Authenticator}
      />
      <Route exact path={'/validate-token'} component={TokenValidator} />
      <Route exact path={'/recovery-password'} component={RecoveryPassword} />
      <MessagesProvider>
        <Switch>
          <Route
            exact
            path={'/pergunte-ao-albert'}
            component={PergunteAlbert}
          />
          <Route
            exact
            path={'/pergunte-ao-albert/chat'}
            component={PergunteAlbertChat}
          />
        </Switch>
      </MessagesProvider>
      <Route
        exact
        path={'/reset-password/:email/:token'}
        component={ResetPassword}
      />
      <Route
        exact
        isPrivate
        path={'/select-company'}
        component={SelectCompany}
      />
      <ChatProvider>
        <Route exact path={'/ai/prompts'} component={PergunteAlbertAI} />
        <Route exact path={'/ai/chat'} component={PergunteAlbertAIChat} />
      </ChatProvider>
      <Route
        exact
        isPrivate
        path={'/create-company'}
        component={CreateCompany}
      />
      <Route
        exact
        isPrivate
        path={'/notifications'}
        component={Notifications}
      />
      <OrdersProvider>
        {/* desabilitado */}
        <Route exact isPrivate path={'/orders'} component={Home} />
        <Route isPrivate path={'/orders/:id'} component={Home} />
        {/*  */}
      </OrdersProvider>
      <Route exact isPrivate path={'/gallery'} component={Gallery} />
      <Route exact isPrivate path={'/settings'} component={Settings} />
      <CartProvider>
        <Route
          exact
          isPrivate
          path={'/extra-services'}
          component={ExtraServices}
        />
        <Route
          exact
          isPrivate
          path={'/extra-services/checkout/:token'}
          component={ExtraServicesCheckout}
        />
      </CartProvider>
      <Route exact isPrivate path={'/users'} component={Users} />
      <Route exact isPrivate path={'/opening-hours'} component={OpeningHours} />
      <Route
        exact
        isPrivate
        path={'/opening-hours/edit/:day'}
        component={EditOpeningHours}
      />
      <Route
        exact
        isPrivate
        path={'/opening-hours/edit'}
        component={BulkEditOpeningHours}
      />
      <Route exact isPrivate path={'/cashback'} component={Cashback} />
      <Route isPrivate path={'/users/:id'} component={User} />
      <Route
        exact
        isPrivate
        path={'/company-profile'}
        component={CompanyProfile}
      />
      <WalletProvider>
        <OrdersProvider>
          <OrderContextProvider>
            <Route exact isPrivate path={'/check-in'} component={CheckIn} />
            <Route
              exact
              isPrivate
              path={'/check-in-resume'}
              component={CheckInResume}
            />
          </OrderContextProvider>
        </OrdersProvider>
        <Route
          exact
          isPrivate
          path={'/financial-order'}
          component={FinancialOrders}
        />
        <Route
          exact
          isPrivate
          path={'/financial/ticket-list'}
          component={TicketList}
        />
        <Route exact isPrivate path={'/financial'} component={Financial} />
        <Route exact isPrivate path={'/balance'} component={Balance} />
        <Route exact isPrivate path={'/withdraw'} component={Withdraw} />
        <Route
          exact
          isPrivate
          path={'/receipt'}
          component={(props: any) => <Receipt {...props} />}
        />
        <Route exact isPrivate path={'/bank-account'} component={Home} />
      </WalletProvider>
      <Route exact isPrivate path={'/cards'} component={Cards} />
      <Route exact isPrivate path={'/token'} component={Token} />
      <Route exact isPrivate path={'/card'} component={Card} />
      <Route isPrivate path={'/card/:id'} component={Card} />
      <Route
        isPrivate
        path={'/card/paymentMethod/add'}
        component={AddPaymentForm}
      />
      <Route exact isPrivate path={'/invoices'} component={Invoices} />
      <Route exact isPrivate path={'/profile'} component={Profile} />
      <Route exact isPrivate path={'/menus'} component={Menus} />
      <Route
        exact
        isPrivate
        path={'/categories/:id/products'}
        component={Items}
      />
      <Route exact isPrivate path={'/products/create'} component={CreateItem} />
      <Route exact isPrivate path={'/products/:id/edit'} component={EditItem} />
      <Route exact isPrivate path={'/offers'} component={Offers} />
      <Route exact isPrivate path={'/offers/create'} component={CreateOffer} />
      <Route exact isPrivate path={'/offers/:id/edit'} component={EditOffer} />
      <Route exact isPrivate path={'/terms'} component={Terms} />
      <Route exact isPrivate path={'/my-term'} component={MyTerm} />
      <Route
        exact
        isPrivate
        path={'/onboarding/:companyId/confirm'}
        component={OnboardingPaymentConfirm}
      />
      <Route
        exact
        isPrivate
        path={'/onboarding/:companyId/payment'}
        component={OnboardingPayment}
      />
      <Route
        exact
        isPrivate
        path={'/onboarding/:companyId'}
        component={Onboarding}
      />
      <WalletProvider>
        <Route
          exact
          isPrivate
          path={'/'}
          component={(props: any) => {
            if (
              !user?.partner?.is_term_accepted &&
              !user?.partner?.user_types
            ) {
              return <Redirect to="/terms" />;
            } else {
              if (!company && !loading) {
                return <Redirect to="/select-company" />;
              }
            }

            const RenderHome =
              HomeTypes[user?.partner?.user_types ?? 'default'];

            return <RenderHome {...props} />;
          }}
        />
      </WalletProvider>
      <Route exact isPrivate path={'/guides'} component={Guides} />
      <Route isPrivate path={'/guides/:slug'} component={Guides} />
      <Route
        exact
        isPrivate
        path={'/cashbacks/expired'}
        component={CashbacksExpired}
      />
      <Route exact isPrivate path={'/promotions'} component={Promotions} />
      <Route
        exact
        isPrivate
        path={'/promotions/create'}
        component={CreatePromotion}
      />
      <Route exact isPrivate path={'/reports'} component={Reports} />
      <Route exact isPrivate path={'/plan/payment'} component={PlanPayment} />
      <Route
        exact
        isPrivate
        path={'/plan/payment/success'}
        component={PlanPaymentSuccess}
      />
      <Route
        exact
        isPrivate
        path={'/services-extras/products'}
        component={ExtraServices}
      />
      <Route
        exact
        isPrivate
        path={'/services-extras/checkout/success'}
        component={ExtraServicesCheckoutSuccess}
      />
    </IonReactRouter>
  );
};

export default Routes;
