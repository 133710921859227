import { PayloadChat } from '../types/chat';
import { api } from './config';

export const getPrompts = async (companyUuid: string) => {
  const { status, data } = await api.get('/v2/partners/ai/prompts', {
    headers: {
      company_id: companyUuid,
    },
  });

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const getChats = async (companyUuid: string, payload: PayloadChat) => {
  const { status, data } = await api.get('/v2/partners/ai/chats', {
    params: payload,
    headers: {
      company_id: companyUuid,
    },
  });

  if (status === 200 && data) {
    return data.data;
  }

  return false;
};

export const getChat = async (companyUuid: string, id: string) => {
  const { status, data } = await api.get(`/v2/partners/ai/chat/${id}`, {
    headers: {
      company_id: companyUuid,
    },
  });

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const deleteChat = async (companyUuid: string, chatId: string) => {
  const { status, data } = await api.delete(`/v2/partners/ai/chat/${chatId}`, {
    headers: {
      company_id: companyUuid,
    },
  });

  if (status === 200 && data) {
    return data.data;
  }

  return false;
};

export const pinChat = async (companyUuid: string, chatId: string) => {
  const { status, data } = await api.patch(
    `/v2/partners/ai/chat/${chatId}/pined`,
    {
      headers: {
        company_id: companyUuid,
      },
    }
  );

  if (status === 200 && data) {
    return data.data;
  }

  return false;
};

export const chatInit = async (companyUuid: string, promptId: string) => {
  const { status, data } = await api.post('/v2/partners/ai/chat', {
    prompt_id: promptId,
  });

  if (status === 201 && data) {
    return data;
  }

  return false;
};

export const sendMessage = async (companyUuid: string, promptId: string) => {
  const { status, data } = await api.post('/v2/partners/ai/chat', {
    params: {
      prompt_id: promptId,
    },
    headers: {
      company_id: companyUuid,
    },
  });

  if (status === 201 && data) {
    return data;
  }

  return false;
};
