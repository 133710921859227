import { api } from './config';
import {
  CustomersCashbackParams,
  CustomersCashbackResponse,
} from './types/cashbacksExpired';

export const reactivateCashback = async <T>(
  ids: string[],
  new_days: string
) => {
  const { status, data } = await api.post(`/v2/partners/cashback/recovery`, {
    idx_transactions: ids,
    new_days: new_days,
  });

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const customersCashbackRequest = async (
  params?: CustomersCashbackParams
): Promise<CustomersCashbackResponse> => {
  try {
    const { data } = await api.get<CustomersCashbackResponse>(
      '/v2/partners/cashback/recovery/customers',
      {
        params: {
          ...params,
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error('Erro ao filtrar associados');
  }
};
