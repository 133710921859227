import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getData } from '../utils/storage';
import { PromotionType, TYPE } from '../pages/Promotions/types';
import {
  createPromotion,
  listPromotions,
  pausePromotion,
  deletePromotion,
  uploadBanner,
  restaurePromotion,
  updateBanner,
} from '../services/promotions';
import { add } from 'date-fns';
import errorHandling from '../utils/error_handling';
const CROP_AREA_ASPECT = 16 / 9;

const types: { name: string; value: TYPE; description: string }[] = [
  {
    name: 'combo',
    value: TYPE.combo,
    description: 'vários produtos em uma promoção.',
  },
  {
    name: 'oferta',
    value: TYPE.offer,
    description: 'desconto sobre produtos.',
  },
  {
    name: 'desconto',
    value: TYPE.discount,
    description: 'desconto na loja toda.',
  },
];

type PromotionContextProps = {
  setPromotions: React.Dispatch<React.SetStateAction<PromotionType>>;
  promotions: any;
  preview: PromotionPreviewProps | null;
  applyPreview: any;
  cropAreaAspect: any;
  types: any[];
  selectType: any;
  applySelectType: any;
  loading: boolean;
  meta: any;
  handlePausePromotion: (data: any) => any;
  handleUpdateBannerPromotion: (id: string, data: any) => any;
  handleGetPromotion: (query: any) => any;
  handleCreatePromotion: (data: PromotionType) => any;
  handleRestaurePromotion: (data: PromotionType) => any;
  handleDeletePromotion: (data: PromotionType) => any;
};

type PromotionContextProviderProps = {
  children: ReactNode;
};

type PromotionPreviewProps = {
  banner?: any;
  croppedArea?: any;
  description?: string;
  rules?: string;
  tags?: { id: string; text: string }[];
  start_date: Date;
  end_date: Date;
  availability?: {
    days: string[];
    time: {
      start: string;
      end: string;
    };
  };
  name?: string;
  type: TYPE.combo | TYPE.offer | TYPE.discount;
  discount_amount: number;
};

const PromotionContext = createContext<PromotionContextProps>({
  setPromotions: () => {},
  promotions: [],
  preview: {
    type: TYPE.combo,
    discount_amount: 0,
    start_date: add(new Date(), { days: 2 }),
    end_date: add(new Date(), { days: 30 }),
  },
  applyPreview: () => {},
  cropAreaAspect: CROP_AREA_ASPECT,
  types: [],
  selectType: {},
  applySelectType: () => {},
  loading: true,
  meta: {},
  handleGetPromotion: () => {},
  handlePausePromotion: () => {},
  handleUpdateBannerPromotion: () => {},
  handleCreatePromotion: () => {},
  handleRestaurePromotion: () => {},
  handleDeletePromotion: () => {},
});

const key = '@AlbertPartners::company';

export default function PromotionContextProvider({
  children,
}: PromotionContextProviderProps) {
  const [promotions, setPromotions] = useState<any>(null!);
  const [preview, setPreview] = useState<PromotionPreviewProps>({
    type: TYPE.combo,
    discount_amount: 0,
    start_date: add(new Date(), { days: 2 }),
    end_date: add(new Date(), { days: 30 }),
  });
  const [selectType, setSelectType] = useState<any>(types[0]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(null);

  const applyPreview = useCallback(
    (data: any) => {
      setPreview({ ...preview, ...data });
    },
    [preview]
  );

  useEffect(() => {
    getData(key).then(async (data: any) => {
      if (data.value) {
        const companyId: string = JSON.parse(data.value).id;
        const { data: getPromotions, meta } = await listPromotions({
          id: companyId,
        });
        setMeta(meta);
        setPromotions((getPromotions as PromotionType) || null!);
      }
    });
  }, []);

  const handleGetPromotion = useCallback(
    async ({ query, status, page = 1 }) => {
      try {
        setLoading(true);
        getData(key)
          .then(async (data: any) => {
            if (data.value) {
              const companyId: string = JSON.parse(data.value).id;
              const { data: getPromotions, meta } = await listPromotions({
                id: companyId,
                query,
                status,
                page,
                order: 'status,asc',
              });
              setMeta(meta);
              setPromotions((getPromotions as PromotionType) || null!);
            }
          })
          .finally(() => setLoading(false));
      } catch (error) {
        return error;
      }
    },
    []
  );

  const handleCreatePromotion = useCallback(async (data: any) => {
    try {
      setLoading(true);
      await getData(key).then(async (company: any) => {
        const companyId: string = JSON.parse(company.value).id;
        const dataPromotion = await createPromotion<PromotionType>(
          data,
          companyId
        );
        if (dataPromotion) {
          await updateBanner(dataPromotion.id, data.banner.file);
        }
      });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateBannerPromotion = useCallback(
    async (id: string, data: any) => {
      setLoading(true);
      await uploadBanner(id, data);
    },
    []
  );

  const handlePausePromotion = useCallback(async (promotion: PromotionType) => {
    try {
      return await pausePromotion({ promotion });
    } catch (err) {
      errorHandling(err, 'erro ao pausar promoção', 'crema');
    }
  }, []);

  const handleRestaurePromotion = useCallback(
    async (promotion: PromotionType) => {
      try {
        return await restaurePromotion({ promotion });
      } catch (err) {
        errorHandling(err, 'erro ao reativar promoção', 'crema');
      }
    },
    []
  );

  const handleDeletePromotion = useCallback(
    async (promotion: PromotionType) => {
      try {
        return await deletePromotion({ promotion });
      } catch (err) {
        errorHandling(err, 'erro ao deletar uma promoção', 'crema');
      }
    },
    []
  );

  return (
    <PromotionContext.Provider
      value={{
        setPromotions,
        promotions,
        preview,
        applyPreview,
        cropAreaAspect: CROP_AREA_ASPECT,
        types,
        selectType,
        applySelectType: setSelectType,
        loading,
        meta,
        handleGetPromotion,
        handlePausePromotion,
        handleUpdateBannerPromotion,
        handleCreatePromotion,
        handleRestaurePromotion,
        handleDeletePromotion,
      }}
    >
      {children}
    </PromotionContext.Provider>
  );
}

export const usePromotionContext = () =>
  useContext<PromotionContextProps>(PromotionContext);
